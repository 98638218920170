<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">
      Wagens ({{ apiData.is_active ? 'Actief' : 'Archief/Inactief' }})
    </h1>
    <button v-if="apiData.is_active" @click="handleToevoegen">+ Wagen toevoegen</button>
    <div class="py-4" :key="apiData.is_active">
      <UITableResponsive :items="items" :headers="headers" :suffix-headers="['Acties']">
        <template v-slot:item-Acties="{ item }">
          <button
            class="btn"
            @click="setActivation(item.id)"
            :class="{
              error: apiData.is_active,
              success: !apiData.is_active,
            }"
          >
            {{ apiData.is_active ? 'Desactiveren' : 'Heractiveren' }}
          </button>
          <button v-if="apiData.is_active" @click="handleBewerken(item)" class="ml-1 btn warn">Wijzigen</button>
        </template>
      </UITableResponsive>
      <UITablePagination class="mt-2" :count="count" v-model:limit="limit" v-model:offset="offset" />
    </div>
  </div>
</template>

<script setup>
import { watch, computed, inject, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import clone from 'just-clone'

import UITableResponsive from '@/components/UI/Table/Responsive'
const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

import usePaginatedApi from '@/hooks/usePaginatedApi'
import useModal from '@/hooks/useModal'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const axios = inject('axios')
const [openFixedModal] = useModal({ fixed: true })

const apiData = computed(() => ({ is_active: !!(route.meta && route.meta.is_active) }))
const { limit, offset, count, items, getData } = usePaginatedApi('/api/dashboard/wagens/list', apiData, {
  defaultLimit: 50,
})

const setActivation = async id => {
  await axios
    .patch(`/api/dashboard/wagens/${apiData.value.is_active ? 'deactivate' : 'activate'}`, { id })
    .then(() => getData())
}

const handleToevoegen = () => {
  openFixedModal('FORM_WAGEN', { callback: getData })
}
const handleBewerken = (wagen) => {
  openFixedModal('FORM_WAGEN', { callback: getData, modelValue: clone(wagen) })
}

watch(
  () => apiData.value.is_active,
  (v, o) => {
    if (v == o) return
    offset.value = 0
    getData()
  },
)

const headers = computed(() => {
  return [
    'ID',
    'Naam',
    'Nummerplaat',
    'VIN',
    'Teken',
    'Merk',
    'Model',
    store.getters.isKantoorPermission ? undefined : 'Kilometerstand',
  ].filter(el => el)
})

// const headers = computed(() => {
//   const result = [
//     'ID',
//     'Naam',
//     'Email',
//     'GSM',
//     'Commisie_%',
//     'Type',
//     'Fulltime',
//   ]
//   if (apiData.value.is_active) {
//     result.push('Wagen')
//   }
//   return result
// })







// const route = useRoute()
// const axios = inject('axios')
// const apiData = computed(() => ({ is_active: !!(route.meta && route.meta.is_active) }))
// const { limit, offset, count, items, getData } = usePaginatedApi('/api/dashboard/wagens/list', apiData)
// const setActivation = async id => {
//   await axios.patch(`/api/dashboard/wagens/${apiData.value.is_active ? 'deactivate' : 'activate'}`, { id })
//   items.value = items.value.filter(u => u.id !== id)
// }
// watch(
//   () => apiData.value.is_active,
//   (v, o) => {
//     if (v == o) return
//     offset.value = 0
//     getData()
//   },
// )

// const handleToevoegen = () => {
//   openFixedModal('FORM_USER', { callback: getData })
// }

// const handleBewerken = (user) => {
//   openFixedModal('FORM_USER', { callback: getData, modelValue: clone(user) })
// }

</script>
