<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">
      Affiliates
    </h1>
    <button @click="handleToevoegen">+ Affiliate toevoegen</button>
    <div class="py-4">
      <UITableResponsive :items="items" :suffix-headers="['Acties']">
        <template v-slot:colgroup>
          <colgroup>
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-24" />
            <col class="w-24" />
            <col class="w-24" />
            <col class="w-auto" />
          </colgroup>
        </template>
        <template v-slot:item-Acties="{ item }">
          <button class="btn warn" @click="handleWijzigen(item)">Wijzigen</button>
        </template>
        <template v-slot:item-affiliate_perc="{ item }">
          {{ item.affiliate_perc }} %
        </template>
        <template v-slot:item-omzet="{ item }">
          &euro;&nbsp;{{ formatPrice(item.omzet) }}
        </template>
        <template v-slot:item-commisie="{ item }">
          &euro;&nbsp;{{ formatPrice(item.commisie) }}
        </template>
        <template v-slot:item-betaald="{ item }">
          &euro;&nbsp;{{ formatPrice(item.betaald) }}
        </template>
      </UITableResponsive>
      <UITablePagination class="mt-2" :count="count" v-model:limit="limit" v-model:offset="offset" />
    </div>
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import clone from 'just-clone'
import UITableResponsive from '@/components/UI/Table/Responsive.vue'
import { formatPrice } from '@/functions/formatNumber'

import usePaginatedApi from '@/hooks/usePaginatedApi'
import useModal from '@/hooks/useModal'

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

const [openFixedModal] = useModal({ fixed: true })

const { limit, offset, count, items, getData } = usePaginatedApi('/api/dashboard/affiliates/list')

const handleToevoegen = () => {
  openFixedModal('FORM_AFFILIATE', { callback: getData })
}

const handleWijzigen = (affiliate) => {
  openFixedModal('FORM_AFFILIATE', { modelValue: clone(affiliate), callback: getData })
}
</script>
